import React from "react";

function Header() {
  return (
    <div className="header">
      <div className="headerText">version 1</div>
    </div>
  );
}

export default Header;
