import React, { useState, useEffect, useRef } from "react";
import StringUI from "./StringUI";
import Toolbar from "./Toolbar";
import autocorrelation from "../util/autocorrelation";

function StringTable(props) {  
  const [frequency, setFrequency] = useState(0);
  const [completedNotes, setCompletedNotes] = useState(Array(props.notes.length).fill(false));
  const [isRecording, setIsRecording] = useState(false);

  // ---------------------------------------------- //

  const audioContextRef = useRef();
  const analyzerRef = useRef();
  const streamRef = useRef();

  var hint = isRecording ? "play a note" : "enable microphone"

  const useRequestAnimationFrame = callback => {
    const requestRef = useRef();
  
    const animate = () => {
      var bufferLength = analyzerRef.current.fftSize;
      var buffer = new Float32Array(bufferLength);
      analyzerRef.current.getFloatTimeDomainData(buffer);
      var autoCorrelateValue = autocorrelation.simpleAutoCorrelation(buffer, audioContextRef.current.sampleRate)
      
      if (autoCorrelateValue !== -1) {
        callback(autoCorrelateValue);
      }

      requestRef.current = requestAnimationFrame(animate);
    };
  
    useEffect(() => {
      if (isRecording) {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
      }
    });
  };

  useRequestAnimationFrame(frequency => {
    setFrequency(frequency);
  });

  // ---------------------------------------------- //

  const getMicrophone = () => {
    audioContextRef.current = new AudioContext();
    analyzerRef.current = audioContextRef.current.createAnalyser();

    analyzerRef.current.minDecibels = -100;
    analyzerRef.current.maxDecibels = -10;
    analyzerRef.current.smoothingTimeConstant = 0.85;


    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    })
    .then(
      function(stream) {
        streamRef.current = stream
        var source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyzerRef.current);
        setIsRecording(true);
      }
    )
    .catch(function(err) {
      setIsRecording(false);
    });
  }

  const stopMicrophone = () => {
    audioContextRef.current.close()
    streamRef.current.getTracks().forEach(track => track.stop());
    setIsRecording(false);
  }

  // ---------------------------------------------- //

  const onChangeFrequency = (event) => {
    setFrequency(event.target.value)
  }

  const onChangeRecording = () => {
    if (isRecording) {
      stopMicrophone();
    } else {
      getMicrophone();
    }
  }

  const onReset = () => {
    const nextCompletedNotes = completedNotes.map((c, i) => {
      return false
    })
    setCompletedNotes(nextCompletedNotes)
  }

  const completeNote = (note) => {
    const nextCompletedNotes = completedNotes.map((c, i) => {
      if (note === i) {
        return true
      }
      return c
    })
    setCompletedNotes(nextCompletedNotes)
  }

  // ---------------------------------------------- //

  const CORRECT_THRESHOLD = 1.5
  var frequencyVH = 0
  var isComplete = true

  props.notes.forEach(note => {
    if (isRecording && note.lowThreshold < frequency && frequency <= note.highThreshold) {
      note.isActive = true

      if (note.lowThreshold < frequency && frequency < note.frequency - CORRECT_THRESHOLD) {
        frequencyVH = - ((note.frequency - frequency) / (note.frequency - note.lowThreshold)) * 30
        note.isCompleting = false
        hint = "too flat"
      } else if (note.frequency + CORRECT_THRESHOLD < frequency && frequency <= note.highThreshold) {
        frequencyVH = ((frequency - note.frequency) / (note.highThreshold - note.frequency)) * 30
        note.isCompleting = false
        hint = "too sharp"
      } else {
        note.isCompleting = true
        hint = ""
      }
    } else {
      note.isCompleting = false
      note.isActive = false
    }

    isComplete &= completedNotes[note.index]
  }); 

  return (
    <div>
      <div className="top-toolbar"/>

      {props.notes.map(note => 
        <StringUI 
          note={note} 
          frequency={frequency} 
          isActive={note.isActive} 
          isComplete={completedNotes[note.index]}
          x={frequencyVH}
          completeNote={completeNote}
        />
      )}

      <Toolbar 
        isComplete={isComplete}
        hint={hint}
        onReset={onReset}

        onChange={onChangeFrequency} 
        frequency={frequency}

        isRecording={isRecording}
        onRecordingChange={onChangeRecording}

        isDebugging={false}
      />
    </div>
  );
}

export default StringTable;