import React from "react";
import StringTable from './StringTable.js';
import helper from "../util/helper.js";

function Tuner() {
  var NOTES = [
    {
      value: 'E',
      frequency: 82,
    },
    {
      value: 'A',
      frequency: 110,
    },
    {
      value: 'D',
      frequency: 147,
    },
    {
      value: 'G',
      frequency: 196,
    },
    {
      value: 'B',
      frequency: 247,
    },
    {
      value: 'E',
      frequency: 330,
    }, 
  ]

  helper.intializeNotes(NOTES)

  return (
    <div>
      <header className="App-header">
        <StringTable 
          notes={NOTES} 
        />
      </header>
    </div>
  );
}

export default Tuner;
