import React, { useState, useEffect, useRef } from "react";
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';

function StringUI(props) {
  const [progress, setProgress] = useState(0);

  const progressTimer = useRef();

  function handleTime() {
    setProgress((prevState) => prevState + 10);
  }

  useEffect(() => {
    if (progress >= 100) {
      clearInterval(progressTimer.current);
      if (!props.isComplete) {
        props.completeNote(props.note.index)
      }
      setProgress(100)
    }

  }, [progress, props]);
  
  useEffect(() => {
    if (props.note.isCompleting) {
      progressTimer.current = setInterval(handleTime, 100);

      return () => {
        clearInterval(progressTimer.current);
      };
    } else {
      setProgress(0)
    }
  }, [props]);

  const stringColor = props.isActive ? "hotpink" : "whitesmoke"
  const noteIndicatorColor = props.note.isCompleting ? "seagreen" : "whitesmoke"

  return (
    <div className="string-ui">
      {/* Completion Indicator */}
      {props.isComplete &&
        <div className="completion-indicator">
            <CheckIcon 
              style={{
                color: 'whitesmoke',
                minWidth: "2vh",
                height: "2vh",
              }}
            />
        </div>
      }
      
      {/* Horizontal Line */}
      <hr
        className="string"
        style={{
            color: stringColor,
            backgroundColor: stringColor,
            borderColor: stringColor,
        }}
      />

      {/* Note Indicator */}
      <div
        style={{
          display: "flex",
          position: "absolute",

          width: "4.5vh",
          height: "4.5vh",

          backgroundColor: noteIndicatorColor,
          borderRadius: "50%",

          alignItems: "center",
          justifyContent: "center",

          zIndex: 1,
        }}>
          {props.note.isCompleting ? 
            <CircularProgress 
              variant="determinate" 
              value={progress}
              thickness={6}
              style={{
                color: "whitesmoke",
                transform: "rotateY(180deg)",
                width: "3vh",
                height: "3vh",
              }} 
            /> :
            <div
              style={{
                fontSize: "3vh",
                color: "black",
              }}
            >
              {props.note.value}
            </div>
          }
      </div>
      
      {/* Current Frquency Indicator */}
      {props.isActive &&
        <div
          style={{
            position: "absolute",
            transform: `translateX(${props.x}vw)`,

            width: "2.2vh",
            height: "2.2vh",
            backgroundColor: "whitesmoke",
            borderRadius: "50%",
        }}/>
      }

    </div>
  );
}

export default StringUI;
