function intializeNotes(NOTES) {
    var lastDiff = 0

    for (let i = NOTES.length - 1; i >= 0; i--) {
        if (i === NOTES.length - 1) {
            const diff = (NOTES[i].frequency - NOTES[i - 1].frequency) / 2
            NOTES[i].highThreshold = NOTES[i].frequency + diff
            NOTES[i].lowThreshold = NOTES[i].frequency - diff
            lastDiff = diff
        } else if (i === 0) {
            NOTES[i].highThreshold = NOTES[i].frequency + lastDiff
            NOTES[i].lowThreshold = NOTES[i].frequency - lastDiff
        } else {
            const diff = (NOTES[i].frequency - NOTES[i - 1].frequency) / 2
            NOTES[i].highThreshold = NOTES[i].frequency + lastDiff
            NOTES[i].lowThreshold = NOTES[i].frequency - diff
            lastDiff = diff
        }

        NOTES[i].index = i
        NOTES[i].isActive = false
        NOTES[i].isCompleting = false
    }
}

const helper = {
    intializeNotes: intializeNotes,
}

export default helper;