import React from "react";
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import TextField from '@mui/material/TextField';

function Toolbar(props) {
  return (
    <div className="bottom-toolbar">

      {props.isDebugging ? 
        <TextField 
          variant="filled"
          onChange={props.onChange}
          value={props.frequency}
          style={{
            width: "10vh",
            marginLeft: "5vw",
            marginRight: "auto",
          }}
        />
        :
        <Button 
          style={{
            backgroundColor: 'steelblue',
            marginLeft: "5vw",
            marginRight: "auto",

            minWidth: "4.5vh",
            height: "4.5vh",
          }} 
          aria-label="delete"
          onClick={props.onRecordingChange}
        >
          {props.isRecording ? 
            <MicIcon
              style={{
                color: 'whitesmoke',
                minWidth: "2.8vh",
                height: "2.8vh",
              }}
            /> 
            :
            <MicOffIcon  
              style={{
                color: 'whitesmoke',
                minWidth: "2.8vh",
                height: "2.8vh",
              }}
            /> 
          }
        </Button>
      }
      
      <div style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "20vh",

        color: 'hotpink',
        fontSize: '2.8vh',
      }}>
        {props.hint}
      </div>

      {props.isComplete ?
        <Button 
          style={{
            backgroundColor: 'seagreen',
            marginLeft: "auto",
            marginRight: "5vw",
            
            minWidth: "4.5vh",
            height: "4.5vh",
          }} 
          aria-label="delete"
          onClick={props.onReset}
        >
          <RestartAltIcon 
            style={{
              color: 'whitesmoke',
              minWidth: "2.8vh",
              height: "2.8vh",
            }}
          />
        </Button> :
          <div 
            style={{
              width: "15vh",
            }}
          />
      }
      
    </div>
  );
}

export default Toolbar;
